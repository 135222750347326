let origin = "https://future.zhuneng.cn";
//积分
const pointsUrl = `/accApi/accPoints/pointuser/getPointUserDetail`;
//党务通
const getTopicListURL = `/accApi/accPoints/topic/getTopicList`;
//民主评议列表
const rightsCommentListUrl = `/accApi/accPoints/cpAssessment/getCpAssessmentList`;
//师资库列表
const getTeacherList = `/accApi/accPoints/cpbTeacher/getCpbTeacherList`;
//获取师资详情
const getCpbTeacherInfoByIdUrl = `/accApi/accPoints/cpbTeacher/getCpbTeacherInfoById`;
//获取课程列表前获取页码
const getInitPageNoUrl = `/accApi/accPoints/cpbCourse/getInitPageNo`;
//党建课程列表查询
const getCpbCourseListUrl = `/accApi/accPoints/cpbCourse/getCpbCourseList`;
// 话题列表
const getTopicListUrl = `/accApi/accPoints/topic/getTopicList`;
// 话题详情
const getTopicInfoByIdUrl = `/accApi/accPoints/topic/getTopicInfoById`;
// 在线学习详情
const getTopicInfoByIdURL = `accApi/accPoints/topic/getTopicInfoById`;
//增加阅读数
const getCountUrl = `/accApi/accPoints/topic/addReadCount`;
export {
  getCpbCourseListUrl,
  pointsUrl,
  getTopicListURL,
  rightsCommentListUrl,
  getTeacherList,
  getCpbTeacherInfoByIdUrl,
  getInitPageNoUrl,
  getTopicListUrl,
  getTopicInfoByIdUrl,
  getTopicInfoByIdURL,
  getCountUrl,
};
